import { Component, Inject, Injector, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { statementLinkedAccountWithBalances } from "@bitwarden/web-vault/app/components/add-balance/add-balance.component";
import { AddTransactionComponent } from "@bitwarden/web-vault/app/components/add-transaction/add-transaction.component";
import { AccountSelectorComponent } from "@bitwarden/web-vault/app/gloss/cabinet/account-selector/account-selector.component";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { transactionData } from "@bitwarden/web-vault/app/models/types/transactionData.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { CabinetService } from "@bitwarden/web-vault/app/services/DataService/vault-file/cabinet.service";
import { SelectAccountService } from "@bitwarden/web-vault/app/services/cabinet/selectAccount.service";

@Component({
  selector: "app-cabinet-file-upload",
  templateUrl: "./cabinet-file-upload.component.html",
})
export class CabinetFileUploadComponent implements OnDestroy {
  selectedFile: File | null = null;
  addTransaction = false;
  addBalance = false;
  addBalanceCompleted = false;
  chooseAccountAddTransactionRef: MatDialogRef<AccountSelectorComponent>;
  chooseAccountDialogue = true;
  linkedAccountsData: statementLinkedAccountWithBalances[];
  uploadFileData: CabinetFileUploadedData;
  uploading = false;
  transactions: transactionData[] = [];
  isAddTransactionForm = true;
  isEditExistingTransaction = false;
  ineditableLinkedAccounts: AccountView[] = [];
  noAccountCanChange = false;
  selectAccountService: SelectAccountService;
  selectedAccount: AccountView;
  inEditAccount: AccountView;
  dialog: MatDialog;
  transactionService: TransactionService;
  globalService: GlobalService;
  cabinetComponentService: CabinetService;
  private destroy$ = new Subject<void>();

  @ViewChild(AddTransactionComponent) addTransactionComponent!: AddTransactionComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeForm: CallableFunction;
    },
    private injector: Injector
  ) {
    this.addBalance = true;
    this.selectAccountService = injector.get(SelectAccountService);
    this.dialog = injector.get(MatDialog);
    this.transactionService = injector.get(TransactionService);
    this.globalService = injector.get(GlobalService);
    this.cabinetComponentService = injector.get(CabinetService);
  }

  receivedAddBalanceComplete($event: boolean) {
    this.addBalanceCompleted = $event;
    if (this.addBalanceCompleted) {
      this.chooseAccountDialogue = false;
      this.addBalance = false;
      this.openDialog();
    }
  }

  receivedLinkedAccountsData($event: statementLinkedAccountWithBalances[]) {
    this.linkedAccountsData = $event;
  }

  receivedSelectedFile(event: File) {
    this.selectedFile = event;
  }

  receivedFileData($event: CabinetFileUploadedData) {
    this.uploadFileData = $event;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AccountSelectorComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        finishUploadForm: this.finishUploadForm.bind(this),
        accountBalanceData: this.linkedAccountsData,
        unavailableAccountOptions: this.ineditableLinkedAccounts,
      },
      disableClose: true,
    });
    this.chooseAccountAddTransactionRef = dialogRef;
  }

  closeCreationOptionsDialogue() {
    this.chooseAccountAddTransactionRef.close();
    this.addTransaction = true;
    this.chooseAccountDialogue = true;
    this.chooseAccountAddTransactionRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.selectAccountService.setSelectedAccount(result.data);
          this.selectedAccount = result.data;
          this.ineditableLinkedAccounts.push(result.data);
          this.isAddTransactionForm = true;
          if (this.ineditableLinkedAccounts.length === this.linkedAccountsData.length) {
            this.noAccountCanChange = true;
          }
        }
      });
  }

  async finishUploadForm() {
    await this.saveUploadFile(this.selectedFile, this.uploadFileData);
    this.saveAllTransactions();
    this.chooseAccountAddTransactionRef.close();
    this.dialog.closeAll();
  }

  addOneMoreTransaction() {
    if (this.isAddTransactionForm) {
      this.saveNewTransaction();
    }
    this.isAddTransactionForm = true;
  }

  saveNewTransaction() {
    this.addTransactionComponent.completeForm();
    this.inEditAccount = null;
    this.isEditExistingTransaction = false;
  }

  //received transaction data from saveNewTransaction /\
  receivedTransactionData($event: transactionData) {
    if ($event) {
      this.transactions.push($event);
      this.transactions = this.transactions.sort((a, b) =>
        a.account.name.localeCompare(b.account.name)
      );
      this.transactions = [...this.transactions];
      this.isAddTransactionForm = false;
    }
  }

  changeSelectedAccount() {
    this.chooseAccountDialogue = false;
    this.openDialog();
  }

  receivedIsEditTransaction($event: transactionData) {
    if ($event) {
      this.isAddTransactionForm = true;
      this.addTransactionComponent.editTransaction($event);
      this.transactions = this.transactions.filter((transaction) => transaction !== $event);
      this.isEditExistingTransaction = true;
      this.inEditAccount = $event.account;
    }
  }

  receivedIsRemoveTransaction($event: transactionData) {
    if ($event) {
      this.transactions = this.transactions.filter((transaction) => transaction !== $event);
      if (this.transactions.length === 0) {
        this.isAddTransactionForm = true;
      }
    }
  }

  async submitFile() {
    this.saveNewTransaction();
    this.isAddTransactionForm = false;
    this.saveAllTransactions();
    await this.saveUploadFile(this.selectedFile, this.uploadFileData);
    this.selectAccountService.setSelectedAccount(null);
    this.dialog.closeAll();
  }

  saveAllTransactions() {
    try {
      this.transactions.forEach((transaction) => {
        this.transactionService.createNormalTransaction(
          transaction.account.originalBook,
          transaction.formData
        );
      });
      this.globalService.showSuccessMessage("success", "allTransactionsAddedSuccessfully");
      this.selectAccountService.setSelectedAccount(null);
    } catch (e) {
      this.globalService.showErrorMessage("error", "transactionFailed");
    }
  }

  async saveUploadFile(selectedFile: File, newBlance: CabinetFileUploadedData) {
    try {
      this.uploading = true;
      const data: CabinetFileUploadedData = {
        name: selectedFile?.name,
        mimeType: selectedFile?.type,
        statementToDate: newBlance.statementToDate,
        statementFromDate: newBlance.statementFromDate,
        statementAccounts: newBlance.statementAccounts,
        openingBalances: newBlance.openingBalances,
        closingBalances: newBlance.closingBalances,
        file: selectedFile,
      };

      const isSaved = await this.cabinetComponentService.saveFile(data);
      if (isSaved) {
        this.data.closeForm(isSaved);
      }
    } catch (error) {
      this.cabinetComponentService.displayCatchError(error);
    } finally {
      this.uploading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
