<ng-container *ngIf="!(wizardService.getIsWizardInProgress() | async)">
  <div *ngIf="!showGraph" class="no-data-container"></div>
  <app-coming-soon-page *ngIf="!hasEarlyAccess"></app-coming-soon-page>
  <main-progress-bar
    *ngIf="!(mainProgressBar.getIsLoadingCompleted() | async)"
    [isFirstTimeUser]="!(preferenceService.hasUserLocation() | async)"
  ></main-progress-bar>
  <app-spinner [showSpinner]="showSpinner"></app-spinner>
  <div class="content" *ngIf="showGraph && hasEarlyAccess && !newBalance">
    <app-primary-summary-graph
      [graphData]="graphData"
      [scenarioData]="scenarioData"
      [defaultDates]="defaultDates"
      [dashboardParameters]="dashboardParameters"
      [defaultGranularity]="defaultGranularity"
      [granularityOptions]="granularityOptions"
      (callFilter)="callFilter($event)"
      (callScenario)="callScenario()"
    ></app-primary-summary-graph>
    <app-filtered-transactions-table-card></app-filtered-transactions-table-card>
  </div>
</ng-container>
