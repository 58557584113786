import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { AutoTransactionDirectionComponent } from "@bitwarden/web-vault/app/components/auto-transaction-direction/auto-transaction-direction.component";
import { DatePickerComponent } from "@bitwarden/web-vault/app/components/form/elements/date-picker/date-picker.component";
import { GlossInputComponent } from "@bitwarden/web-vault/app/components/inputs/gloss-input/gloss-input.component";
import { TransactionForm } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/transaction/transaction-form";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { transactionData } from "@bitwarden/web-vault/app/models/types/transactionData.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { SelectAccountService } from "@bitwarden/web-vault/app/services/cabinet/selectAccount.service";

@Component({
  selector: "app-add-transaction",
  templateUrl: "./add-transaction.component.html",
})
export class AddTransactionComponent implements OnInit, OnDestroy {
  loading = false;
  transactionFrom: TransactionForm;
  selectAccountService: SelectAccountService;
  selectedAccount: AccountView;
  private destroy$ = new Subject<void>();

  @Output() transactionData = new EventEmitter<transactionData | null>();

  @ViewChild("descriptionInput") descriptionInput!: GlossInputComponent;
  @ViewChild("timeInput") timeInput!: GlossInputComponent;
  @ViewChild("amountInput") amountInput!: GlossInputComponent;
  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  @ViewChild(AutoTransactionDirectionComponent)
  autoTransactionDirectionComponent: AutoTransactionDirectionComponent;

  constructor(private injector: Injector) {
    this.selectAccountService = injector.get(SelectAccountService);
  }

  ngOnInit(): void {
    this.selectAccountService
      .getSelectedAccount()
      .pipe(takeUntil(this.destroy$))
      .subscribe((account) => {
        this.selectedAccount = account;
        this.transactionFrom = new TransactionForm(this.injector, this.selectedAccount);
      });
  }

  completeForm() {
    this.descriptionInput.inputValueControl.markAsTouched();
    this.amountInput.inputValueControl.markAsTouched();
    this.descriptionInput.onInputBlur();
    this.amountInput.onInputBlur();
    const result = this.transactionFrom.submit();
    this.transactionData.emit(result);
    if (result) {
      this.resetValue();
    }
  }

  resetValue() {
    this.descriptionInput.clearInput();
    this.timeInput.clearInput();
    this.amountInput.clearInput();
    this.datePickerComponent.resetDate();
    this.autoTransactionDirectionComponent.setDirectionValue("IN");
    this.transactionFrom.formGroup.reset({
      date: null,
      currency: this.transactionFrom.currency,
      transactionStatus: TransactionStatusEnum.transaction,
      type: "IN",
    });
  }

  editTransaction(inActionTransaction: transactionData) {
    this.transactionFrom.currentAccount = this.selectedAccount;
    this.transactionFrom.selectedAccount = inActionTransaction.account;
    this.transactionFrom.isEditTransaction = true;
    if (inActionTransaction) {
      const editData = inActionTransaction.formData;
      //reset the form view
      this.descriptionInput.setInputValue(editData.description);
      this.timeInput.setInputValue(editData.time);
      this.amountInput.setInputValue(editData.amount.toString());
      this.datePickerComponent.setDateText(editData.date);
      this.autoTransactionDirectionComponent.transactionDirectionSelected(editData.type);
      //reset the form value
      this.transactionFrom.formGroup.reset({
        description: editData.description,
        time: editData.time,
        date: editData.date,
        amount: editData.amount,
        currency: editData.currency,
        transactionStatus: TransactionStatusEnum.transaction,
        type: editData.type,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
