import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import {
  actionButtonOptions,
  amountInputOptions,
} from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/component.options";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { BookBalance } from "@bitwarden/web-vault/app/shared/utils/helper.book/balance";

/**
 * Component for editing the balance in a wizard. Require one account to be present  with a balance set on it already.
 */
@Component({
  selector: "app-edit-balance-in-wizard",
  templateUrl: "./edit-balance-in-wizard.component.html",
})
export class EditBalanceInWizardComponent implements OnInit {
  /**
   * Options for the balance input field.
   * Includes a callback for when the input is blurred.
   */
  protected readonly balanceInputOptions = {
    ...amountInputOptions,
    inputBlurred: (value: string) => this.setBalance(value),
  } as GlossInputOptions;

  /**
   * Options for the save button.
   * Includes a callback for when the button is clicked.
   */
  protected readonly saveButtonOptions = new ActionButton({
    ...actionButtonOptions,
    onClick: this.updateBalance.bind(this),
  });

  private accountView: AccountView;
  private balanceForm: BalanceForm;
  private balanceTransaction: Transaction;
  private dashboardService: DashboardService;

  /**
   * Constructor for the component.
   * @param data - Data injected into the component.
   * @param bookService - Service for handling book data.
   * @param injector - Injector for dependency injection.
   * @param transactionService - Service for handling transaction data.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { closeDialog: CallableFunction },
    private bookService: BookService,
    private injector: Injector,
    private transactionService: TransactionService
  ) {
    this.dashboardService = injector.get(DashboardService);
    this.dashboardService.resetDashboardData();
  }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Fetches the account view and transactions.
   */
  async ngOnInit(): Promise<void> {
    const _views = await this.bookService.getBooksView();
    this.accountView = _views[0];
    const transactions = await this.transactionService.getAccountTransactions(
      this.accountView.originalBook
    );
    this.balanceTransaction = transactions[0];
  }

  /**
   * Sets the balance form with the provided balance value.
   * @param balance - The balance value to set.
   */
  setBalance(balance: string) {
    this.balanceForm = {
      balance: balance,
      currency: null,
      date: null,
      transactionStatus: null,
    };
  }

  /**
   * Updates the balance using the BookBalance utility.
   * Displays an error if the balance is invalid.
   * Closes the dialog if the balance is successfully updated.
   */
  async updateBalance() {
    const bookBalance = new BookBalance(this.accountView, this.balanceForm, this.injector);
    const isBalance = bookBalance.isBalance();
    if (!isBalance) {
      bookBalance.error.displayError();
      return;
    }

    // TODO: use BookBalance to update the balance
    this.balanceTransaction.bankImportedBalance = Number(this.balanceForm.balance);
    await this.transactionService.update(this.balanceTransaction);
    this.closeDialog();
  }

  closeDialog() {
    this.data.closeDialog("close");
  }
}
