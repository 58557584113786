<form *ngIf="transactionFrom.formGroup" [formGroup]="transactionFrom.formGroup" name="accountForm">
  <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
    <app-gloss-input
      #descriptionInput
      [options]="transactionFrom.descriptionInputOptions"
      class="tw-min-h-12 tw-w-full tw-bg-white"
    ></app-gloss-input>
    <div class="tw-flex tw-justify-between tw-gap-4 sm:tw-gap-6 tb:tw-flex-col">
      <app-date-picker
        [label]="'date' | i18n"
        [isRequired]="true"
        [hasError]="
          transactionFrom.formGroup.get('date').invalid &&
          transactionFrom.formGroup.get('date').touched
        "
        (changeDateEvent)="transactionFrom.onDateChange($event)"
        class="tw-w-full"
      >
      </app-date-picker>
      <app-gloss-input
        #timeInput
        [options]="transactionFrom.timeInputOptions"
        class="tw-w-full"
      ></app-gloss-input>
    </div>
    <div class="tw-flex tw-justify-between tw-gap-4 tb:tw-flex-col">
      <div class="tw-flex tw-w-1/2 tw-justify-between xs:tw-w-full">
        <app-auto-transaction-direction
          [label]="'transactionDirection' | i18n"
          (selectTransactionDirection)="transactionFrom.selectDirection($event)"
          class="tw-w-[47%]"
        ></app-auto-transaction-direction>
        <app-currencies
          (selectedCurrency)="transactionFrom.selectCurrency($event)"
          [label]="'symbol' | i18n"
          class="tw-w-[47%]"
        ></app-currencies>
      </div>
      <div class="tw-w-1/2 xs:tw-w-full">
        <app-gloss-input
          #amountInput
          [options]="transactionFrom.amountInputOptions"
          class="tw-w-full"
        ></app-gloss-input>
      </div>
    </div>
  </div>
</form>
