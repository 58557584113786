<div class="tw-flex tw-flex-col tw-gap-12">
  <!--        statement period-->
  <div class="tw-flex tw-w-full tw-flex-col">
    <span class="tw-font-medium">{{ "statementPeriod" | i18n }} *</span>

    <!--        todo: @Horace temporary datepicker until new design-->
    <div class="tw-flex tw-w-full tw-gap-4">
      <!--      From picker-->
      <div class="tw-flex tw-w-full tw-gap-4">
        <div
          class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-pl-2 md:tw-pl-0"
          (click)="fromPicker.open()"
        >
          <div
            class="tw-relative tw-z-10 tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
          >
            {{ "fromDate" | i18n }}
          </div>
          <div
            class="left-round-border tw-relative tw-flex tw-h-auto tw-min-h-[48px] tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
          >
            <div class="tw-relative tw-flex tw-items-center tw-justify-center">
              <mat-datepicker #fromPicker></mat-datepicker>
              <input
                matInput
                [matDatepicker]="fromPicker"
                (dateChange)="onDateChange($event, 'fromDate')"
                class="tw-pointer-events-none tw-absolute tw-opacity-0"
              />
              <div
                class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
                [ngClass]="{ 'tw-opacity-60': !fromDateText }"
              >
                {{ fromDateText || "YYYY-MM-DD" }}
              </div>
            </div>
          </div>
        </div>
        <!--to Picker-->
        <div
          class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-pr-2"
          (click)="toPicker.open()"
        >
          <div
            class="tw-relative tw-z-10 tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
          >
            {{ "toDate" | i18n }}
          </div>
          <div
            class="right-round-border tw-relative tw-flex tw-h-auto tw-min-h-[48px] tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
          >
            <div class="tw-relative tw-flex tw-items-center tw-justify-center">
              <mat-datepicker #toPicker></mat-datepicker>
              <input
                matInput
                [matDatepicker]="toPicker"
                (dateChange)="onDateChange($event, 'toDate')"
                class="tw-pointer-events-none tw-absolute tw-opacity-0"
              />
              <div
                class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
                [ngClass]="{ 'tw-opacity-60': !toDateText }"
              >
                {{ toDateText || "YYYY-MM-DD" }}
              </div>
            </div>
            <div class="tw-overflow-hidden tw-rounded">
              <svg class="tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#calendar" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #accountDisplayTemplate let-data="data">
    <div class="sm:tw-flex sm:tw-flex-col">
      <span
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "opening" | i18n }}:
        {{ _mOpeningBalances.get(data.id)?.balanceForm?.balance | currency: baseCurrency }}</span
      >
      <span
        *ngIf="_mClosingBalances.get(data.id)"
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "closing" | i18n }}:
        {{ _mClosingBalances.get(data.id)?.balanceForm?.balance | currency: baseCurrency }}</span
      >
    </div>
  </ng-template>

  <app-item-display
    [items]="linkedAccountsDisplay"
    (editFunction)="receivedEditAccount($event)"
    (removeFunction)="receivedRemoveAccount($event)"
  >
  </app-item-display>

  <div class="tw-flex tw-flex-col">
    <div
      class="tw-flex tw-flex-col tw-gap-2 tw-rounded-xl tw-bg-neutral-50 tw-p-3 tw-shadow-button"
    >
      <span class="tw-font-medium">{{ "statementAccount" | i18n }} *</span>

      <mat-select
        #accountSelect
        (selectionChange)="accountSelected($event)"
        class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-white tw-shadow-inner"
        placeholder="Search to add existing account tags"
        panelClass="tw-bg-neutral-50"
      >
        <mat-option
          *ngFor="let account of accountViews"
          [value]="account"
          [disabled]="isAccountLinked(account)"
        >
          {{ account.name }}
        </mat-option>
      </mat-select>

      <!-- statement balance -->
      <div class="tw-flex tw-w-full tw-flex-col" [ngClass]="{ 'tw-hidden': !accountInAction }">
        <span class="tw-font-medium">{{ "statementBalance" | i18n }} *</span>
        <div class="tw-flex tw-w-full tw-justify-around tw-gap-4">
          <!-- Opening balance input -->
          <div
            (click)="openingBalanceInput.focus()"
            class="left-round-border tw-flex tw-w-[50%] tw-items-center tw-justify-between tw-gap-3 tw-bg-white tw-py-3 tw-px-6 tw-shadow-inner sm:tw-mt-7 sm:tw-flex-auto"
          >
            <span class="sm:tw--mt-[65px] sm:tw--ml-[20px] sm:tw-w-0">{{ "opening" | i18n }}</span>
            <input
              #openingBalanceInput
              (input)="openingBalanceChange($event)"
              type="number"
              placeholder="0.00"
              class="tw-w-full tw-border-0 tw-text-left tw-text-neutral-300"
            />
            <span class="tw-uppercase tw-text-neutral-300">{{ baseCurrency }}</span>
          </div>
          <span *ngIf="openingBalanceError" class="mat-error">{{ openingBalanceErrorText }}</span>
          <!-- Closing balance input -->
          <div
            (click)="closingBalanceInput.focus()"
            class="right-round-border tw-flex tw-w-[50%] tw-items-center tw-justify-between tw-gap-3 tw-bg-white tw-py-3 tw-px-6 tw-shadow-inner sm:tw-mt-7 sm:tw-flex-auto"
          >
            <span class="sm:tw--mt-[65px] sm:tw--ml-[20px] sm:tw-w-0">{{ "closing" | i18n }}</span>
            <input
              #closingBalanceInput
              (input)="closingBalanceChange($event)"
              type="number"
              placeholder="0.00"
              class="tw-w-full tw-border-0 tw-text-left tw-text-neutral-300"
            />

            <span class="tw-uppercase tw-text-neutral-300">{{ baseCurrency }}</span>
          </div>
        </div>
      </div>
    </div>

    <button
      (click)="linkSelectedAccount()"
      class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button hover:tw-cursor-pointer"
    >
      <span class="tw-text-4 tw-pr-[10px] tw-font-medium tw-tracking-[0.0025em] tw-text-primary">{{
        "linkAccount" | i18n
      }}</span>
      <svg class="tw-h-4 tw-w-4">
        <use xlink:href="images/sprite.svg#add" />
      </svg>
    </button>
  </div>
  <div class="tw-flex tw-justify-end">
    <app-gloss-button
      class="tw-w-1/2 xs:tw-w-full"
      [options]="{
        type: 'submit',
        text: ('complete' | i18n),
        onClick: addBalanceComplete.bind(this),
        isEnabled: true,
        class: 'primary'
      }"
    >
    </app-gloss-button>
  </div>
</div>
