import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { transactionData } from "@bitwarden/web-vault/app/models/types/transactionData.types";

@Component({
  selector: "app-display-transactions",
  templateUrl: "./display-transactions.component.html",
})
export class DisplayTransactionsComponent implements OnChanges {
  transformedTransactions: {
    label: string;
    templateData: transactionData;
    template: TemplateRef<any>;
  }[] = [];
  @Input() newTransactions: transactionData[];
  @Output() isEditTransaction: EventEmitter<transactionData> = new EventEmitter();
  @Output() isRemoveTransaction: EventEmitter<transactionData> = new EventEmitter();
  @ViewChild("transactionAmount", { static: true }) transactionAmountTemplate: TemplateRef<any>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.newTransactions) {
      this.updateTransformedTransactions();
    }
  }

  updateTransformedTransactions(): void {
    this.transformedTransactions = this.newTransactions.map((transaction) => ({
      label: `${transaction.account.name} - ${transaction.formData.description}`,
      template: this.transactionAmountTemplate,
      templateData: transaction,
    }));
  }

  receivedEditTransaction(transaction: transactionData) {
    this.isEditTransaction.emit(transaction);
  }

  receivedRemoveTransaction(transaction: transactionData) {
    this.isRemoveTransaction.emit(transaction);
  }
}
