import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-item-display",
  templateUrl: "./item-display.component.html",
})
export class ItemDisplayComponent {
  @Input() items: any;
  @Output() editFunction: EventEmitter<any> = new EventEmitter();
  @Output() removeFunction: EventEmitter<any> = new EventEmitter();

  editFunctionEmitter(item: any) {
    this.editFunction.emit(item.templateData);
  }

  removeFunctionEmitter(item: any) {
    this.removeFunction.emit(item.templateData);
  }
}
