<ng-container>
  <div class="account-info-container tw-ml-3 tw-flex tw-flex-col tw-text-lg tw-font-medium">
    <span
      class="account-name tw-mb-0.5 tw-text-[13px] tw-font-medium tw-leading-[125%] tw-text-[#292D32]"
      >{{ accountData.account.name }}</span
    >
    <div class="sm:tw-flex sm:tw-flex-col">
      <span
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "opening" | i18n }}:
        {{ accountData.openingBalance | currency: accountData.baseCurrency }}</span
      >
      <span
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "closing" | i18n }}:
        {{ accountData.closingBalance | currency: accountData.baseCurrency }}</span
      >
    </div>
  </div>
</ng-container>
