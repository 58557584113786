import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { IFileValidator } from "@bitwarden/web-vault/app/models/interfaces/file-validator.interface";
import { fileTypeAllow } from "@bitwarden/web-vault/app/shared/utils/file-upload/file-type";
import { FileValidator } from "@bitwarden/web-vault/app/validators/file-validator/file-validator";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
})
export class FileUploadComponent implements OnInit {
  errorMessage: string | null = null;
  fileName = "";
  fileValidator: IFileValidator;
  fileTypeAllowed: string = fileTypeAllow.map((type) => "." + type).join(",");

  @Output() selectedFile = new EventEmitter<File>();
  @Input() isClickComplete = false;

  constructor(private globalService: GlobalService) {
    this.fileValidator = new FileValidator(this.globalService);
  }

  ngOnInit() {
    // eslint-disable-next-line no-console
    console.log("FileUploadComponent initialized");
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];
    if (file) {
      const newEvent = { target: { files: [file] } };
      this.fileSelected(newEvent);
    }
  }

  fileSelected(event: any) {
    this.errorMessage = null;
    const file = event.target.files[0];
    if (file) {
      if (!this.fileValidator.validate(file)) {
        this.errorMessage = this.fileValidator.errorMessage;
        this.fileName = this.fileValidator.fileName;
        return;
      }
      this.selectedFile.emit(file);
      this.fileName = file.name;
    }
  }
}
