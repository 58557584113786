<div class="linked-accounts-container" *ngIf="items.length > 0">
  <span class="tw-font-medium">{{ "linkedCabinetAccounts" | i18n }} *</span>
  <div
    *ngFor="let item of items"
    class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button"
  >
    <div class="account-info-container tw-flex tw-flex-col">
      <span
        class="account-name tw-mb-0.5 tw-text-[13px] tw-font-semibold tw-leading-[125%] tw-text-[#292D32]"
        >{{ item.label }}</span
      >
      <ng-container
        *ngTemplateOutlet="item.template; context: { data: item.templateData }"
      ></ng-container>
    </div>

    <div class="account-buttons-container tw-flex sm:tw-flex-col">
      <button
        (click)="editFunctionEmitter(item)"
        class="btn-container tw-mr-3 tw-flex tw-h-[30px] tw-cursor-pointer tw-items-center tw-justify-between tw-gap-[6px] tw-rounded-[8px] tw-border tw-border-blue-200 tw-bg-[#FCFCFC] tw-px-3 tw-py-2 sm:tw-mb-1 sm:tw-mr-0 sm:tw-block sm:tw-w-auto"
      >
        <span
          class="btn-text tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.005em] tw-text-neutral-800 sm:tw-hidden"
          >{{ "edit" | i18n }}</span
        >

        <svg class="tw-h-5 tw-w-5">
          <use xlink:href="images/sprite.svg#add-entry" />
        </svg>
      </button>

      <button
        (click)="removeFunctionEmitter(item)"
        class="btn-container tw-mr-3 tw-flex tw-h-[30px] tw-cursor-pointer tw-items-center tw-justify-between tw-gap-[6px] tw-rounded-[8px] tw-border tw-border-blue-200 tw-bg-[#FCFCFC] tw-px-3 tw-py-2 sm:tw-mb-1 sm:tw-mr-0 sm:tw-block sm:tw-w-auto"
      >
        <span
          class="btn-text tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.005em] tw-text-neutral-800 sm:tw-hidden sm:tw-flex-none"
          >{{ "delete" | i18n }}</span
        >

        <svg class="tw-h-5 tw-w-5">
          <use xlink:href="images/sprite.svg#trash" />
        </svg>
      </button>
    </div>
  </div>
</div>
