<div class="progress-bar-container">
  <div
    class="tw-inline-flex tw-h-[250px] tw-w-[320px] tw-flex-col tw-items-center tw-gap-4 tw-rounded-xl tw-bg-white tw-px-12 tw-py-10"
  >
    <div class="tw-flex tw-gap-2">
      <div class="tw-text-xl tw-font-medium">{{ "calculatingScenarios" | i18n }}</div>
      <div class="tw-flex tw-items-end tw-justify-center tw-space-x-1 tw-pb-2">
        <div
          class="tw-h-1 tw-w-1 tw-animate-bounce tw-rounded-full tw-bg-black [animation-delay:-0.3s]"
        ></div>
        <div
          class="tw-h-1 tw-w-1 tw-animate-bounce tw-rounded-full tw-bg-black [animation-delay:-0.15s]"
        ></div>
        <div class="tw-h-1 tw-w-1 tw-animate-bounce tw-rounded-full tw-bg-black"></div>
      </div>
    </div>
    <!--    <ng-container *ngIf="isFirstTimeUser; else notFirstTimeUser">-->
    <ng-container *ngIf="isFirstTimeUser; else notFirstTimeUser">
      <app-spinner [hasContainer]="false" [showSpinner]="true"></app-spinner>
    </ng-container>
    <ng-template #notFirstTimeUser>
      <!--      outer-->
      <div
        class="tw-flex tw-h-28 tw-w-28 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-border-neutral-50"
      >
        <!--        %-->
        <div
          class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-to-neutral-50 tw-p-2"
          [ngStyle]="getBackgroundStyle()"
        >
          <!--   inner-->
          <div
            class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-bg-white"
          >
            <!--        %-->
            <div>{{ loadingPercentage }}%</div>
          </div>
        </div>
      </div>
      <div class="tw-text-center">
        <span class="tw-font-semibold tw-text-primary"
          >{{ "step" | i18n }} {{ mainProgressBar.getScenarioIndex() | async }}</span
        >
        <span class="tw-font-medium">{{ mainProgressBar.getProgressLabel() | async }}</span>
      </div>
    </ng-template>
  </div>
</div>
