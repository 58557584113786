import { Component, EventEmitter, Output, Input } from "@angular/core";

export type transactionDirectionType = "IN" | "OUT";

@Component({
  selector: "app-auto-transaction-direction",
  templateUrl: "./auto-transaction-direction.component.html",
})
export class AutoTransactionDirectionComponent {
  transactionDirection: string[] = ["IN", "OUT"];
  direction: transactionDirectionType = "IN";

  @Input() label: string;
  @Input() newDirection: transactionDirectionType;

  @Output() selectTransactionDirection = new EventEmitter<transactionDirectionType>();

  /**
   * Emits the transactionDirectionSelect event to deal with create transaction event.
   * @param {string} direction - The direction that was selected.
   */
  transactionDirectionSelected(direction: transactionDirectionType) {
    if (this.selectTransactionDirection) {
      this.selectTransactionDirection.emit(direction);
      this.setDirectionValue(direction);
    }
  }

  selectionCleared() {
    this.selectTransactionDirection.emit(null);
  }

  setDirectionValue(direction: transactionDirectionType) {
    this.direction = direction;
  }
}
