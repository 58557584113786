<ng-container *ngIf="showSpinner">
  <div *ngIf="hasContainer; else noContainer" class="spinner-container">
    <ng-container *ngTemplateOutlet="spinnerTemplate"></ng-container>
  </div>
  <ng-template #noContainer>
    <ng-container *ngTemplateOutlet="spinnerTemplate"></ng-container>
  </ng-template>
</ng-container>

<ng-template #spinnerTemplate>
  <div class="gloss-spinner tw-z-[10000]">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
