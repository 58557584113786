import { Component, Input } from "@angular/core";

import { statementLinkedAccountWithBalances } from "@bitwarden/web-vault/app/components/add-balance/add-balance.component";

@Component({
  selector: "app-account-selector-options",
  templateUrl: "./account-selector-options.component.html",
})
export class AccountSelectorOptionsComponent {
  @Input() accountData: statementLinkedAccountWithBalances;
}
