import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { DateUtil } from "@bitwarden/web-vault/app/shared/utils/helper.date/date-util";

export class GlossDate {
  static readonly EPOCH_DATE: string = "1970-01-01";

  private _date: Date;
  private _dateString: string;
  private _time: string = undefined;
  private _timeZone: string = undefined;

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get dateString(): string {
    return this._dateString;
  }

  set dateString(value: string) {
    this._dateString = value;
  }

  get time(): string | undefined {
    return this._time;
  }

  set time(value: string | undefined) {
    this._time = value;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  set timeZone(value: string) {
    this._timeZone = value;
  }

  static getDateAsString(dateType: GlossDate): string {
    const { date, time, timeZone } = dateType;

    return `${date}${time ? "T" + time : ""}${timeZone ? "" + timeZone : ""}`;
  }

  static getEpochDateTime() {
    return this.EPOCH_DATE;
  }

  static getNow() {
    return new Date();
  }

  static extractDate(transaction: Transaction) {
    const glossDate = transaction["_transactionDate"];
    return glossDate.date;
  }

  setToDateObj(transactionDate: GlossDate | string): GlossDate {
    if (transactionDate instanceof GlossDate) {
      this.date = new Date(transactionDate.date);
      return transactionDate;
    }

    let dateString;
    if (typeof transactionDate === "object") {
      this.timeZone = transactionDate["_timeZone"];
      this.time = transactionDate["_time"];
      if (transactionDate?.["_dateString"]) {
        dateString = transactionDate["_dateString"];
      } else {
        dateString = transactionDate["_date"];
      }
    } else {
      dateString = transactionDate;
    }
    return this.process(dateString);
  }

  /**
   * Process the GlossDate to set the correct value of the properties
   * date: Date object
   * time: string - set to undefined if date provided is 00:00:00Z
   * timeZone: string - set to undefined if date provided is 00:00:00Z
   *
   * @param transactionDate
   */
  private process(transactionDate: string) {
    if (!transactionDate) {
      return;
    }

    this.date = new Date(transactionDate);
    this.dateString = transactionDate;

    if (DateUtil.isTimeSetFromDate(transactionDate)) {
      this.time = this.date.getTime().toString();
    }

    return this;
  }

  getMonthDifference(date: GlossDate): number {
    let startYear, startMonth, endYear, endMonth;

    // Determine which date is earlier
    if (this.date.getTime() < date.date.getTime()) {
      startYear = this.date.getFullYear();
      startMonth = this.date.getMonth();
      endYear = date.date.getFullYear();
      endMonth = date.date.getMonth();
    } else {
      startYear = date.date.getFullYear();
      startMonth = date.date.getMonth();
      endYear = this.date.getFullYear();
      endMonth = this.date.getMonth();
    }

    // Calculate the month difference
    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    // Return the absolute value to ensure the result is always positive
    return Math.abs(monthDifference);
  }

  static getCurrentGlossDate(): GlossDate {
    const date = new Date();
    const nowGlossDate = new GlossDate();

    nowGlossDate.date = date;
    nowGlossDate.dateString = date.toISOString().split("T")[0];
    nowGlossDate.time = date.toTimeString().split(" ")[0];
    nowGlossDate.timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    return nowGlossDate;
  }

  getDateTimeAndZoneString(): string {
    return `${this.dateString.split("T")[0]}${this.time ? ", " + this.time : ""}${
      this.timeZone ? ", " + this.timeZone : ""
    }`;
  }

  static getTableDate(glossDate: GlossDate): string {
    const date = new Date(glossDate.date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return year + "-" + month + "-" + day;
  }

  get WeekNumber() {
    // Following the ISO week date system, the week that contains the first Thursday (of the year) is the first week, a week starts on Monday, and that there are maximal 53 weeks in a year.
    // Reference: https://en.wikipedia.org/wiki/ISO_week_date
    const date = this.date;
    const startOfYear = new Date(date.getFullYear(), 0, 1); // 1 January
    const daysDifference = Math.floor(
      (date.getTime() - startOfYear.getTime()) / 86400000 // 1000 * 60 * 60 * 24
    );

    const startOfWeek = new Date(startOfYear);
    const firstThursday = new Date(startOfYear);
    firstThursday.setDate(startOfYear.getDate() + ((4 - startOfYear.getDay() + 7) % 7)); // get the date of the first Thursday (in a year)
    // 4 - startOfYear.getDay() = the difference between Thursday and the current day of the week
    // + 7 makes sure the result is not negative
    // & 7 ensures that the result is within the setDate's range of 0-6 (where Sunday represents 0)

    const weekNumber = Math.ceil((daysDifference + (startOfWeek.getDay() + 1)) / 7) + 1;

    if (date < firstThursday) {
      return 1;
    } else if (weekNumber > 53) {
      return 52;
    } else {
      return weekNumber;
    }
  }

  getQuarter() {
    const date = this.date;
    return Math.floor(date.getMonth() / 3) + 1;
  }
}
