import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { typeOf } from "mathjs";

import "./multi-select.component.scss";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { MultiSelectableItem } from "@bitwarden/web-vault/app/models/types/general-types";

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styles: ["multi-select.component.scss"],
})
export class MultiSelectComponent implements OnChanges {
  constructor(private i18nService: I18nService) {}

  /** the label for the particular selectable... eg: Accounts or Classifications */
  @Input() labelKey: string;

  /** all the items that the component handles. no need to modify this one */
  @Input() readonly items: MultiSelectableItem[];

  /** the items that are selected and passed down to component */
  @Input() readonly preSelectedItems: MultiSelectableItem[];

  /** the key to use to display the option to the user */
  @Input() displayKey: string;

  /** the html id for particular select element */
  @Input() selectElementId: string;

  /** If select element need a search bar or not */
  @Input() isSearchable = false;

  /** The event that will trigger the selected options to the parent. Sends back the selected ones  */
  @Output() applyEvent = new EventEmitter();

  /** It helps to close the options panel on close button */
  @ViewChild("select") select: MatSelect;

  /** the items that are selected */
  selectedItems: MultiSelectableItem[];

  /** the items that are displayed. If it is searchable then depending on the search this will reset */
  displayedItems: MultiSelectableItem[];

  /** set to true when changes applied, so we can handle some logic in onPanelClosed based on how panel is closed */
  isApplied = false;

  /** set to true when users selected options are not the same as preselected ones. Used for enabling Apply button  */
  isNewOption = false;

  applyButtonOptions = new ActionButton({
    class: "primary",
    fullClass: "round-corner-button",
    text: this.i18nService.t("apply"),
    onClick: this.apply.bind(this),
    isEnabled: false,
    testId: "accountFilterApplyButton",
  });

  cancelButtonOptions = new ActionButton({
    class: "neutral",
    fullClass: "round-corner-button",
    text: this.i18nService.t("cancel"),
    onClick: this.cancel.bind(this),
    isEnabled: true,
    testId: "accountFilterCancelButton",
  });

  /** To make sure that when these parameters are passed they affect the component */
  ngOnChanges(changed: SimpleChanges) {
    if (changed.preSelectedItems) {
      this.selectedItems = this.preSelectedItems;
    }

    if (changed.items) {
      this.displayedItems = this.items;
    }
  }

  /** when user check/uncheck an option */
  onOptionCheck(selectedOptions: MultiSelectableItem[]) {
    this.selectedItems = selectedOptions;
    this.isNewOption = this.getIsNewOption();
    this.applyButtonOptions = new ActionButton({
      ...this.applyButtonOptions,
      isEnabled: this.isNewOption,
    });
  }

  /** check if the selected options are different then preselected ones. If not we disable the Apply button */
  getIsNewOption() {
    if (this.selectedItems.length !== this.preSelectedItems.length) {
      return true;
    }

    for (let i = 0; i < this.selectedItems.length; i++) {
      const isInPreselected = this.preSelectedItems.includes(this.selectedItems[i]);
      if (!isInPreselected) {
        return true;
      }
    }

    return false;
  }

  /** when options panel is closed, we need to make sure the selected options are unharmed if it is not applied */
  onPanelClosed() {
    if (!this.isApplied) {
      this.selectedItems = this.preSelectedItems;
    }

    this.isApplied = false;
    this.applyButtonOptions = new ActionButton({ ...this.applyButtonOptions, isEnabled: false });
  }

  /** closes the options panel */
  closePanel(isApplied: boolean) {
    this.isApplied = isApplied;
    this.select.close();
  }

  /** Apply the selected options by passing them to the parent component */
  apply() {
    this.applyEvent.emit(this.selectedItems);
    this.closePanel(true);
  }

  /** Cancel the changes and close the options panel */
  cancel() {
    this.closePanel(false);
  }

  /** Returns the text to be shown in the options */
  getDisplayValue(item: MultiSelectableItem) {
    if (typeOf(item) === "string") {
      return item;
    } else {
      return (item as { [key: string]: any })[this.displayKey];
    }
  }
}
