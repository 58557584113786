<ng-template #transactionAmount let-data="data">
  <div class="sm:tw-flex sm:tw-flex-col">
    <span
      class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
    >
      {{ data.formData.date }} ({{ data.formData.time }}) ,
    </span>
    <span
      class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
    >
      <span [ngClass]="data.formData.type === 'IN' ? 'tw-text-success' : 'tw-text-danger'">{{
        data.formData.type | i18n
      }}</span>
      {{ data.formData.amount | currency: data.formData.currency }}</span
    >
  </div>
</ng-template>

<app-item-display
  [items]="transformedTransactions"
  (editFunction)="receivedEditTransaction($event)"
  (removeFunction)="receivedRemoveTransaction($event)"
>
</app-item-display>
