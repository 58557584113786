export enum GranularityBucketKeyFormat {
  day = "yyyy-MM-dd",
  week = "RRRR-I",
  month = "yyyy-MM",
  quarter = "yyyy-q",
  year = "yyyy",
}

export enum SummationBy {
  balance = "balance",
  balanceNormalized = "balanceNormalized",
  quantity = "quantity",
  normalizedValuation = "normalizedValuation",
  valuation = "valuation",
}
