<div [hidden]="!chooseAccountDialogue">
  <div class="modal-container">
    <modal-header [title]="'uploadNewFile' | i18n" [showBackButton]="false" [enableTooltip]="false">
    </modal-header>
    <div class="m-body tw-p-10 xs:tw-px-5">
      <form
        name="accountForm"
        class="tw-max-h-[700px] tw-overflow-y-auto tw-p-2 xs:tw-max-h-full xs:tw-overflow-y-hidden xs:tw-p-0"
      >
        <div class="tw-flex tw-flex-col tw-gap-12">
          <app-file-upload (selectedFile)="receivedSelectedFile($event)"></app-file-upload>

          <div *ngIf="addBalance">
            <app-add-balance
              [selectedFile]="selectedFile"
              (addBalanceCompleted)="receivedAddBalanceComplete($event)"
              (outputLinkedAccounts)="receivedLinkedAccountsData($event)"
              (outputFileData)="receivedFileData($event)"
            ></app-add-balance>
          </div>

          <div *ngIf="addTransaction && selectedAccount" class="tw-flex tw-flex-col tw-gap-3.5">
            <app-display-transactions
              [newTransactions]="transactions"
              (isEditTransaction)="receivedIsEditTransaction($event)"
              (isRemoveTransaction)="receivedIsRemoveTransaction($event)"
            ></app-display-transactions>
            <div class="tw-pb-5">
              <div class="tw-flex tw-items-center tw-justify-between">
                <p>
                  Add transaction to
                  <span class="tw-font-medium"
                    >{{
                      isEditExistingTransaction && inEditAccount
                        ? inEditAccount.name
                        : selectedAccount.name
                    }}
                    <button
                      (click)="changeSelectedAccount()"
                      [disabled]="noAccountCanChange"
                      class="tw-items-center tw-justify-center tw-p-3"
                      [ngClass]="{
                        'tw-opacity-30': noAccountCanChange,
                        'hover:tw-cursor-pointer': !noAccountCanChange
                      }"
                    >
                      <svg class="tw-h-4 tw-w-4">
                        <use xlink:href="images/sprite.svg#sync-all" />
                      </svg>
                    </button>
                  </span>
                </p>
              </div>
              <div [hidden]="!isAddTransactionForm">
                <div class="tw-flex tw-justify-between tw-pb-1">
                  <label class="autocomplete-label tw-font-medium">{{
                    "transactionDescription" | i18n
                  }}</label>
                  <button
                    *ngIf="isEditExistingTransaction"
                    (click)="saveNewTransaction()"
                    class="btn-container tw-mr-3 tw-flex tw-h-[30px] tw-cursor-pointer tw-items-center tw-justify-between tw-gap-[6px] tw-rounded-[8px] tw-border tw-border-solid tw-border-primary tw-bg-[#FCFCFC] tw-px-3 tw-py-2 sm:tw-mb-1 sm:tw-mr-0 sm:tw-block sm:tw-w-auto"
                  >
                    <span
                      class="btn-text tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.005em] tw-text-neutral-800 sm:tw-hidden sm:tw-flex-none"
                      >{{ "update" | i18n }}</span
                    >
                    <svg class="tw-h-4 tw-w-4">
                      <use xlink:href="images/sprite.svg#save" />
                    </svg>
                  </button>
                </div>
                <app-add-transaction
                  (transactionData)="receivedTransactionData($event)"
                ></app-add-transaction>
              </div>
            </div>
            <button
              (click)="addOneMoreTransaction()"
              class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button hover:tw-cursor-pointer"
            >
              <span
                class="tw-text-4 tw-pr-[10px] tw-font-medium tw-tracking-[0.0025em] tw-text-primary"
                >{{ "addMore" | i18n }}</span
              >
              <svg class="tw-h-4 tw-w-4">
                <use xlink:href="images/sprite.svg#add" />
              </svg>
            </button>
            <div class="tw-flex tw-justify-end tw-pt-5">
              <app-gloss-button
                class="tw-w-1/2 xs:tw-w-full"
                [options]="{
                  type: 'submit',
                  text: uploading ? ('uploading' | i18n) : ('saveAllTransactions' | i18n),
                  onClick: this.submitFile.bind(this),
                  isEnabled: !uploading,
                  class: 'primary'
                }"
              ></app-gloss-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
