import { Injector } from "@angular/core";
import { Validators } from "@angular/forms";

import { transactionData } from "@bitwarden/web-vault/app/models/types/transactionData.types";

import { transactionDirectionType } from "../../../../components/auto-transaction-direction/auto-transaction-direction.component";
import { InputTypes } from "../../../../models/enum/inputTypes.enum";
import { PreferenceType } from "../../../../models/enum/preferenceType";
import { TransactionStatusEnum } from "../../../../models/enum/transactionType";
import { UserLocationCurrencyEnum } from "../../../../models/enum/user-location.enum";
import { FormInterface } from "../../../../models/interfaces/form.interface";
import { GlossInputOptions } from "../../../../models/types/general-types";
import { AccountView } from "../../../../models/view/account.view";
import { BaseForm } from "../../../../shared/form/BaseForm";
import { HelperNumericInput } from "../../../../shared/utils/helper-numeric-input";
import { amountInputOptions, defaultInputOptions } from "../accounts-add-edit/component.options";

export class TransactionForm extends BaseForm implements FormInterface {
  currency: string;
  transactionType: "IN" | "OUT" = "IN";
  isEditTransaction = false;
  currentAccount: AccountView = null;

  selectedAccount: AccountView;
  amountInputOptions: GlossInputOptions;
  timeInputOptions: GlossInputOptions;
  descriptionInputOptions: GlossInputOptions;

  constructor(injector: Injector, selectedAccount: AccountView) {
    super(injector);
    this.selectedAccount = selectedAccount;

    this.title = this.i18nService.t("enterAccountData");
    this.initialize();
  }

  async initialize() {
    const { AU, HK } = UserLocationCurrencyEnum;
    const userLocation = await this.preferenceService.get(PreferenceType.userLocation);
    this.currency = userLocation === "AU" ? AU : HK;

    this.formGroup = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      amount: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: [this.dateFormat.getDateStringFromStamp(new Date().getTime()), Validators.required],
      time: [null],
      description: [null, Validators.required],
      transactionStatus: TransactionStatusEnum.transaction,
      type: [this.transactionType, Validators.required],
    });

    //ex is amountFieldOptions
    this.amountInputOptions = {
      ...amountInputOptions,
      isRequired: true,
      label: this.i18nService.t("amount"),
      onInput: (event: Event) => this.onInputNumeric(event, "amount"),
      inputBlurred: () => this.clearDefaultValue("amount"),
    };

    this.timeInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("time"),
      placeholder: "00:00:00",
      isRequired: false,
      type: InputTypes.time,
      onInput: (event: Event) => this.onInput(event, "time"),
    };

    this.descriptionInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("description"),
      placeholder: this.i18nService.t("description"),
      isRequired: true,
      onInput: (event: Event) => this.onInput(event, "description"),
      customisedInputOption: true,
    };
  }

  selectDirection(selectedDirection: transactionDirectionType) {
    this.formGroup.controls.type.setValue(selectedDirection);
  }

  triggerDateValidation() {
    if (!this.isEditTransaction && !this.formGroup.controls.date.touched) {
      this.onDateChange("");
    }
  }

  // the submit logic has been passed to the component who instantiate this class
  submit() {
    let result: transactionData = null;
    this.triggerDateValidation();
    result = {
      account: this.selectedAccount,
      formData: this.formGroup.value,
    };
    if (this.formGroup.valid) {
      if (this.currentAccount) {
        this.selectedAccount = this.currentAccount;
        this.currentAccount = null;
      }
      this.isEditTransaction = false;
      return result;
    }
  }
}
