<div
  class="tw-relative tw-mt-5 tw-flex tw-h-12 tw-w-[240px] tw-items-center tw-justify-around tw-rounded-full tw-bg-neutral-50 tw-shadow-button xs:tw-w-[220px]"
>
  <mat-select
    #select
    multiple
    panelClass="panel-dropdown tw-px-1 tw-w-[240px] xs:tw-w-[220px]"
    [(ngModel)]="selectedItems"
    (selectionChange)="onOptionCheck(selectedItems)"
    [id]="selectElementId"
    (closed)="onPanelClosed()"
  >
    <mat-select-trigger>
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-1">
        <span> {{ "viewingAccounts" | i18n: preSelectedItems.length || 0 }}</span>
        <svg class="tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#down-arrow" />
        </svg>
      </div>
    </mat-select-trigger>
    <div class="visible-overflow tw-max-h-[200px]">
      <!--TODO Find a way for when all the options are unselected and remove the disabled below-->
      <mat-option
        class="option-class tw-w-full tw-rounded-xl tw-p-1"
        *ngFor="let item of displayedItems"
        [value]="item"
        [disabled]="selectedItems.length === 1 && selectedItems[0] === item"
      >
        {{ getDisplayValue(item) }}
      </mat-option>
    </div>

    <div class="tw-mb-1 tw-flex tw-flex-row tw-justify-between">
      <app-gloss-button [options]="cancelButtonOptions"></app-gloss-button>
      <app-gloss-button [options]="applyButtonOptions"></app-gloss-button>
    </div>
  </mat-select>
</div>
