import { Injectable, Injector } from "@angular/core";
import { addDays, addMonths, addWeeks, addYears, format, isBefore, subDays } from "date-fns";
import { BehaviorSubject, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import {
  DashboardParameters,
  dashboards,
  scenarioOnly,
  transactionAndScenario,
  transactionOnly,
} from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { GraphScenarioElements } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-scenario-elements";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import {
  Category,
  getDefaultCategory,
} from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import {
  Classification,
  getDefaultClassification,
} from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import {
  dayGranularity,
  GranularityDefaults,
  GranularityOptions,
  granularityProperties,
  GranularityProperty,
  monthGranularity,
  weekGranularity,
  yearGranularity,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { DashboardFilter } from "@bitwarden/web-vault/app/models/types/dashboard.types";
import { FilterParameters, GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  AnchorPointData,
  GroupScenarioBalance,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { TransactionFilter } from "@bitwarden/web-vault/app/models/types/transaction.types";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";
import { DateStartPreferences } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceAlignment/balanceAlignment";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BalanceGroupingTools } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGroupingTools";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";
import { RevaluationService } from "@bitwarden/web-vault/app/services/DataCalculationService/revaluation/revaluation.service";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { BlobbyUtils } from "@bitwarden/web-vault/app/services/blobby/blobbyUtils";
import { PrimarySummaryGraphService } from "@bitwarden/web-vault/app/services/dashboard/graph/primary-summary-graph-service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { BalanceAlignmentWorkerService } from "@bitwarden/web-vault/app/services/web-worker/balance-alignment/balance-alignment-worker.service";
import { BalanceGroupingWorkerService } from "@bitwarden/web-vault/app/services/web-worker/balance-grouping/balance-grouping-worker.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import { sortTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/sort";
import {
  getFirstTransactionDate,
  getLastTransactionDate,
  getStartingFilterDate,
} from "@bitwarden/web-vault/app/shared/utils/helper.transactions/transaction-date";

const DEFAULT_NUMBER_OF_MONTHS_TO_SHOW = 12;

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  protected _defaultStartDate: BehaviorSubject<string>;
  protected _defaultEndDate: BehaviorSubject<string>;
  protected _dashboardID: BehaviorSubject<string>;
  protected _defaultGranularity: BehaviorSubject<GranularityProperty>;
  protected _granularityOptions: BehaviorSubject<Array<GranularityProperty>>;
  protected _filteredTransactions = new BehaviorSubject<Transaction[]>([]);
  protected _dashboardFilter = new BehaviorSubject<DashboardFilter>(null);
  protected _graphData = new BehaviorSubject<GraphDataSet[]>([]);
  protected _isSpinner = new BehaviorSubject<boolean>(false);
  protected _scenarioData = new BehaviorSubject<ScenarioData>({
    scenario: [],
    balance: [],
  });
  protected _scenarioIndex = new BehaviorSubject<number>(null);
  protected _groupBalances = new BehaviorSubject<BalanceGrouping>(undefined);
  protected _mockAccounts = new BehaviorSubject<Array<Book>>(undefined);
  protected _dashboardConfig = new BehaviorSubject<DashboardParameters>(dashboards["2"]);
  readonly defaultDateFormat: string;
  private transactionNormalizeService: TransactionNormalizeService;
  private readonly initialScenarioKey = 2; // 2 is the scenario-3's key

  defaultStartDate$: Observable<string>;
  defaultEndDate$: Observable<string>;
  dashboardID$: Observable<string>;
  defaultGranularity$: Observable<GranularityProperty>;
  granularityOptions$: Observable<Array<GranularityProperty>>;
  filteredTransactions$: Observable<Transaction[]> = this._filteredTransactions.asObservable();
  graphData$: Observable<GraphDataSet[]> = this._graphData.asObservable();
  dashboardFilter$: Observable<DashboardFilter> = this._dashboardFilter.asObservable();
  isSpinner$: Observable<boolean> = this._isSpinner.asObservable();
  scenarioData$: Observable<ScenarioData> = this._scenarioData.asObservable();
  groupBalances$: Observable<BalanceGrouping> = this._groupBalances.asObservable();
  mockAccounts$: Observable<Array<Book>> = this._mockAccounts.asObservable();
  dashboardConfig$: Observable<DashboardParameters> = this._dashboardConfig.asObservable();
  _scenarioIndex$: Observable<number> = this._scenarioIndex.asObservable();

  period: RecurringPeriod;
  balancedTransactions: Array<Transaction>; // all the imported transactions balanced with no revals and no filters
  graphTransactions: Array<Transaction>; // all the transactions with revals required for graphing including fake transactions
  filteredTransactions: Array<Transaction>; // the filtered transactions with revals to be used in the graph and the table
  tableTransactions: Array<Transaction>;
  mockAccounts: Array<Book>;
  startingBalance: Record<string, GroupingNode>;
  anchorPointBalance: Record<string, GroupingNode>;
  groupedBalanceWithoutRevals: BalanceGrouping;
  groupedBalances: BalanceGrouping;
  allAccounts: Array<Book> = [];
  allCategories: Array<Category> = [];
  allClassifications: Array<Classification> = [];
  filteredAccounts: Array<Book> = [];
  filteredDirection: Array<TransactionDirection> = [];
  filteredCategories: Array<Category> = [];
  filteredClassifications: Array<Classification> = [];

  dashboardID: string;
  dashboardConfig: DashboardParameters;
  tableType = "scenario";
  scenarioKey: number;
  scenarioGraph: GraphScenarioElements;
  graphData: GraphDataSet[];
  scenarioData: ScenarioData;
  firstStartingDate: string;
  filterStartDate: string;
  filterEndDate: string;
  estimateEndDate: string;
  anchorDate: string;
  balanceEndDate: string;
  defaultScenarioStartDate: string;
  tomorrow: string;
  granularity: GranularityProperty;
  defaultGranularity: GranularityProperty = monthGranularity; // the default granularity option for this date range
  granularityOptions: Array<GranularityProperty> = granularityProperties; // the available granularity options for this date range
  sliderDates: Array<string>;
  datePreferences: DateStartPreferences;

  useEstimates = false;
  isVaultPurge = false;
  showGraph = false;

  // These attributes can be used to drive a progress bar
  currentAction: string;
  progressPercentage: number;

  private classificationService: ClassificationService;

  private isInitialised = false;

  // TODO: we will need to store the base currency in user settings at some point and call that instead of this
  baseCurrency: string;

  t0: number;
  t1: number;

  constructor(
    private blobbyService: BlobbyService,
    private primarySummaryGraphService: PrimarySummaryGraphService,
    private transactionService: TransactionService,
    private revaluationService: RevaluationService,
    private helperPreferences: HelperPreference,
    private transactionCalculationService: TransactionCalculationService,
    private logger: LogService,
    private dataRepositoryService: DataRepositoryService,
    private injector: Injector,
    private perfService: PerformanceService
  ) {
    this.defaultDateFormat = DateFormatPipe.defaultFormat;

    this.initialiseDates();
    this.initialiseGranularity();
    this.initialiseDashboardSettings();
  }

  setIsSpinner(isSpinner: boolean) {
    this._isSpinner.next(isSpinner);
  }

  async setBaseCurrency() {
    const preferenceCurrency = await this.helperPreferences.getBaseCurrency();
    if (preferenceCurrency !== this.baseCurrency) {
      this.baseCurrency = preferenceCurrency;
    }
  }

  initialiseDates() {
    this.estimateEndDate = format(
      new Date(new Date().getFullYear(), new Date().getMonth() + 24, -1),
      "yyyyMMdd"
    );
    this.tomorrow = format(
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() + 1),
      "yyyyMMdd"
    );

    this._defaultStartDate = new BehaviorSubject(null);
    this.defaultStartDate$ = this._defaultStartDate.asObservable();

    this._defaultEndDate = new BehaviorSubject(null);
    this.defaultEndDate$ = this._defaultEndDate.asObservable();

    this.filterStartDate = this.setFormattedStartDate();
    this.filterEndDate = this.setFormattedEndDate();
  }

  initialiseGranularity() {
    this._defaultGranularity = new BehaviorSubject(this.defaultGranularity);
    this.defaultGranularity$ = this._defaultGranularity.asObservable();

    this._granularityOptions = new BehaviorSubject(this.granularityOptions);
    this.granularityOptions$ = this._granularityOptions.asObservable();

    this.updateGranularityOptions();
  }

  initialiseDashboardSettings() {
    // TODO: when we implement custom dashboards we should retrieve the users preferred default dashboard from blobby
    // until then, the custom dashboard is '1' which represents the transactions only dashboard
    this.dashboardID = "2";
    this._dashboardID = new BehaviorSubject(this.dashboardID);
    this.dashboardID$ = this._dashboardID.asObservable();
    this.loadDashboardConfig();
  }

  /**
   * updateDates - function is triggered when dates are updated on the dashboard
   *
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   */
  async updateDates(startDate: string, endDate: string): Promise<void> {
    this.filterStartDate = startDate;
    this.filterEndDate = endDate;

    this.updateGranularityOptions();
    await this.updateGraph(true);
  }

  async updateGranularity(granularity: GranularityProperty) {
    this.granularity = granularity;
    // when a granularity other than the default is selected clear the default granularity,
    // so it will trigger a change the next time it is set
    this.defaultGranularity = null;
    this._defaultGranularity.next(this.defaultGranularity);
    if (this.dashboardConfig.type === transactionOnly) {
      await this.graphTransactionsOnly(false);
    } else if (this.dashboardConfig.type === scenarioOnly) {
      await this.graphScenario(true, false);
    } else if (this.dashboardConfig.type === transactionAndScenario) {
      await this.graphScenario(true, false);
    }
  }

  /**
   * updateDashboardID - this function is triggered when a new dashboard is chosen from the dropdown list
   * @param dashboardID
   */
  async updateDashboardID(dashboardID: string) {
    if (this.dashboardID != dashboardID) {
      this.dashboardID = dashboardID;

      // load the preferences of the new dashboard ID:
      this.loadDashboardConfig();
      this.resetFilters();
      this.updateGranularityOptions();

      if (this.dashboardConfig.type === transactionOnly) {
        await this.fitStartingDateInAYear();
      } else {
        await this.updateGraph(false);
      }
    }
  }

  async fitStartingDateInAYear() {
    const startDate = getStartingFilterDate(this.balancedTransactions, this.defaultDateFormat);

    const endDate = format(new Date(), this.defaultDateFormat);
    await this.updateDates(startDate, endDate);
    this._defaultStartDate.next(startDate);
    this._defaultEndDate.next(endDate);
  }

  async updateTransactionsTable(type: string, key: number, setSliderDates = true) {
    this.tableType = type;
    this.scenarioKey = key;
    this._scenarioIndex.next(key);

    // filter the required transaction by the slider dates
    await this.updateFilterDates(this.filterStartDate, this.filterEndDate, setSliderDates);

    if (type === "transaction") {
      // update the grouped balance to be the balance grouping
      this._groupBalances.next(this.groupedBalances);
    } else {
      this._groupBalances.next(this.scenarioData.scenario[this.scenarioKey].groupedBalance);
    }
  }

  async setBalancedTransactions(transactions: Array<Transaction>) {
    this.t1 = performance.now();
    this.balancedTransactions = transactions;
    const defaultStartDate = this.setDefaultStartDate(this.balancedTransactions);
    this.firstStartingDate =
      typeof defaultStartDate !== "string" ? defaultStartDate.toUTCString() : defaultStartDate;
    // TODO: continue processing the calculations from grouping here
    await this.updateGraph(false);
    this.logger.info(`Number of transactions in dash: ${this.tableTransactions.length}`);
  }

  loadDashboardConfig() {
    if (dashboards?.[this.dashboardID]) {
      this.dashboardConfig = dashboards[this.dashboardID];
      this._dashboardConfig.next(this.dashboardConfig);
    }
  }

  /**
   * Function needs to filter the balanced transactions
   * Find the starting balance on the start date of the filter from the unfiltered balance
   * Get the revals for the starting balance and filtered transactions
   * Create the graph data from the new grouped balance
   *
   *
   * @param accounts
   * @param directions
   * @param categories
   * @param classifications
   * @param symbol
   * @param refreshBalance
   */
  async filterDashboardTransactions(
    accounts: Book[],
    directions: TransactionDirection[],
    categories: Category[],
    classifications: Classification[],
    symbol?: string,
    refreshBalance = true
  ): Promise<void> {
    this._dashboardFilter.next({
      accounts,
      directions,
      categories,
      classifications,
      symbol,
    });
    this.perfService.mark("DashboardService::filterDashboardTransactions");
    this.sliderDates = null;
    this.filteredAccounts = accounts;
    this.filteredDirection = directions;
    this.filteredCategories = categories;
    this.filteredClassifications = classifications;
    const includeEmptyCategories = categories.some((cat) => cat.name === "No Category");
    const includeEmptyClassifications = classifications.some(
      (cat) => cat.name === "No Classification"
    );

    this.balanceEndDate = this.filterEndDate;
    let balanceStartDate = this.filterStartDate;

    // if the graph includes a scenario then we want the end date to be the anchor date if the filtered end date is
    // past the anchor date
    if (this.dashboardConfig.scenario === true) {
      if (new Date(this.filterEndDate) > new Date(this.anchorDate)) {
        // end the balance transactions on the anchor date if it does past
        this.balanceEndDate = this.anchorDate;
      }
      if (new Date(this.filterStartDate) > new Date(this.anchorDate)) {
        // start the balance transactions on the anchor date if the start date is further
        balanceStartDate = this.anchorDate;
      }
    }

    const filter: TransactionFilter = {
      accounts,
      startDate: balanceStartDate,
      endDate: this.balanceEndDate,
      directions,
      categories,
      classifications,
      symbol,
    };

    // TODO: should the filtering include balance alignment (they won't have categories or classifications)
    this.perfService.mark("DashboardService::getTransactionsOfFilter");

    const granularityList =
      this.granularity !== dayGranularity ? [this.granularity, dayGranularity] : [this.granularity];

    const fillDaily = this.granularity === dayGranularity || this.dashboardConfig.scenario;

    if (refreshBalance) {
      this.filteredTransactions = await this.transactionService.getTransactionsOfFilter(
        filter,
        includeEmptyCategories,
        includeEmptyClassifications,
        this.balancedTransactions
      );

      // get the GlossBalance object on the start date of the graph
      this.perfService.mark("DashboardService::setStartingGlossBalance");
      await this.setStartingGlossBalance();
      this.perfService.mark("DashboardService::createRevalTransactions");
      await this.createRevalTransactions();

      this.perfService.mark("DashboardService::recalculateBalance");

      if (this.graphTransactions) {
        this.t0 = performance.now();
        new BalanceGroupingWorkerService(
          this.graphTransactions,
          this.datePreferences,
          true,
          fillDaily,
          ["account"],
          granularityList,
          this.setGroupedBalance.bind(this)
        );
      }
    } else {
      // check if the granularity needs to be created or if daily, then filled
      await this.checkGranularity(this.groupedBalances);
      await this.completeGraphWithGroupedBalance();
    }
  }

  async updateFilterDates(filterStartDate: string, filterEndDate: string, setSliderDates = true) {
    if (this.tableType === "transaction") {
      this.setTableTransactionDataFromGraphData();
    } else {
      await this.setTableTransactionsFromScenarioData();
    }

    if (setSliderDates) {
      this.sliderDates = [filterStartDate, filterEndDate];
    }

    if (this.sliderDates) {
      await this.updateSliderDates(this.sliderDates[0], this.sliderDates[1]);
    }
    this._mockAccounts.next(this.mockAccounts);
  }

  async updateSliderDates(sliderStartDate: string, sliderEndDate: string) {
    this.tableTransactions = await this.transactionService.filterByDates(
      sliderStartDate,
      sliderEndDate,
      this.tableTransactions
    );
    this._filteredTransactions.next(this.tableTransactions);
  }

  getMockAccountPerId(id: string): Book | null {
    if (this.scenarioData?.scenario?.length > 0) {
      for (const scenario of this.scenarioData.scenario) {
        if (scenario?.createdRecords?.accounts?.length > 0) {
          for (const account of scenario.createdRecords.accounts) {
            if (account.id === id) {
              return account;
            }
          }
        }
      }
    }
    return null;
  }

  async initialise() {
    return this.blobbyService.isInitialised().then(async () => {
      if (!this.isInitialised) {
        this.isInitialised = true;
        this.logger.debug("initialising dashboard service");

        if (this.isVaultPurge) {
          this.resetDashboardData();
        }
      }
      // reload default currency
      await this.setBaseCurrency();
    });
  }

  async refresh(isVaultPurge: boolean) {
    this.isVaultPurge = isVaultPurge;
    this.isInitialised = false;
    await this.initialise();
  }

  async getAllTransactions(refreshTransactions = false) {
    await this.setBaseCurrency();
    if (!this.balancedTransactions || refreshTransactions) {
      await this.initDashboard();
    }
  }

  /**
   * updateGraph - After loading the dashboard config, refresh the graph and table based on dashboard type
   */
  async updateGraph(keepSelectedDates = false) {
    this.sliderDates = null;
    if (this.dashboardConfig.type === transactionOnly) {
      this.perfService.mark("updateGraph::transactionOnly");
      await this.graphTransactionsOnly(true);
    } else if (this.dashboardConfig.type === scenarioOnly) {
      this.perfService.mark("updateGraph::scenarioOnly");
      await this.graphScenario(keepSelectedDates, true);
    } else if (this.dashboardConfig.type === transactionAndScenario) {
      this.perfService.mark("updateGraph::transactionAndScenario");
      await this.graphScenario(keepSelectedDates, true);
    }

    this.perfService.markEnd();
  }

  combineFilterParameters(): FilterParameters {
    return {
      accounts: this.filteredAccounts,
      allAccounts: this.allAccounts,
      directions: this.filteredDirection,
      allDirections: Object.values(TransactionDirection),
      categories: this.filteredCategories.filter((cat) => cat.name !== "No Category"),
      allCategories: this.filteredCategories.filter((cat) => cat.name !== "No Category"),
      classifications: this.filteredClassifications.filter(
        (cls) => cls.name !== "No Classification"
      ),
      allClassifications: this.filteredClassifications.filter(
        (cls) => cls.name !== "No Classification"
      ),
    };
  }

  setGraphData(graphData: Array<GraphDataSet>, triggerObservable: boolean) {
    this.graphData = graphData;
    if (triggerObservable) {
      this._graphData.next(graphData);
    }
  }

  setScenarioData(scenarioData: ScenarioData) {
    this.scenarioData = scenarioData;
    this._scenarioData.next(scenarioData);
  }

  /**
   * Trigger the following observables
   */
  async triggerTransactionObservables() {
    this._filteredTransactions.next(this.tableTransactions);
    this._groupBalances.next(this.groupedBalances);
  }

  private async initDashboard() {
    this.perfService.start("DashboardService::initDashboard");
    this.perfService.mark("Set Currency");
    await this.setBaseCurrency();

    this.perfService.mark("getAllBooks");
    this.allAccounts = await this.dataRepositoryService.getAllBooks();
    this.filteredAccounts = this.allAccounts;

    this.perfService.mark("getAllCategories");
    this.allCategories = await this.dataRepositoryService.getAllCategories();
    this.filteredCategories = [...this.allCategories, getDefaultCategory()];

    this.perfService.mark("getAllClassifications");
    this.allClassifications = await this.dataRepositoryService.getAllClassifications();
    this.filteredClassifications = [...this.allClassifications, getDefaultClassification()];
    this.filteredDirection = [TransactionDirection.Out, TransactionDirection.In];

    const balanceGroupingTools = new BalanceGroupingTools();
    this.datePreferences = await balanceGroupingTools.getDatePreferences(this.injector);

    // get all the transactions from the transactionService
    this.perfService.mark("getAllTransactions");
    let importedTransactions = await this.dataRepositoryService.getAllTransactions();
    this.showGraph = importedTransactions.length > 0;
    // sort the transactions by date
    this.perfService.mark("sortTransaction");

    importedTransactions = importedTransactions.reverse();

    importedTransactions = importedTransactions.sort(sortTransaction);

    if (importedTransactions.length > 0) {
      const referenceData = await this.dataRepositoryService.getAllReferenceData();
      this.t0 = performance.now();
      new BalanceAlignmentWorkerService(
        importedTransactions,
        this,
        this.baseCurrency,
        referenceData,
        this.datePreferences
      );
    }

    /** Set the default startDate and filterStartDate to first transaction date */
    const defaultStartDate = this.setDefaultStartDate(this.balancedTransactions);
    this.firstStartingDate =
      typeof defaultStartDate !== "string" ? defaultStartDate.toUTCString() : defaultStartDate;
    this.filterStartDate = this.firstStartingDate;

    this.updateGranularityOptions();

    this._defaultStartDate.next(this.filterStartDate);
    this._defaultEndDate.next(this.filterEndDate);
    this.perfService.mark("updateGraph");

    /** Create estimates until 1 year from the current date */
    /*
    if (this.useEstimates) {
      this.estimateTransactions = await this.estimatesService.generateEstimateTransactions(
        this.tomorrow,
        this.estimateEndDate
      );

      if (this.estimateTransactions.length > 0) {
        allTransactions = allTransactions.concat(this.estimateTransactions);
      }
    }
    */
    this.perfService.markEnd();
  }

  /**
   * set this.allTransactions to be the filtered transactions with the reval transactions
   */
  async createRevalTransactions() {
    this.perfService.mark("DashboardService::createRevalTransactions");
    let revalTransactions = [...this.filteredTransactions];
    if (this.startingBalance) {
      const startingTransactions = await this.createTransactionsFromBalance();
      revalTransactions = [...startingTransactions, ...revalTransactions];
    }
    const endDate = addDays(new Date(this.filterEndDate), 1).toDateString();

    let graphTransactions = revalTransactions;
    if (revalTransactions.length > 0) {
      graphTransactions = await this.revaluationService.generateRevaluations(
        revalTransactions,
        endDate
      );
    }

    graphTransactions = graphTransactions.sort(sortTransaction);

    this.graphTransactions = graphTransactions;
    this.setTableTransactionDataFromGraphData();
    this.perfService.markEnd();
  }

  async loadDashboardData() {
    this.perfService.mark("DashboardService::loadDashboardData");
    await this.getAllTransactions(true);
    this.perfService.markEnd();
  }

  private setFormattedStartDate() {
    return format(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() - DEFAULT_NUMBER_OF_MONTHS_TO_SHOW,
        1
      ),
      this.defaultDateFormat
    );
  }

  private setFormattedEndDate() {
    return format(new Date(), this.defaultDateFormat);
  }

  resetDashboardData() {
    this.groupedBalances = null;
    this.balancedTransactions = [];
    this.filteredTransactions = [];
    this.graphTransactions = [];
    this.tableTransactions = [];
    this.groupedBalanceWithoutRevals = null;
    this.startingBalance = null;
    this.setScenarioData({
      scenario: [],
      balance: [],
    });
    this.setGraphData([], true);
    this.resetFilters();
  }

  /**
   * The function will get the gloss balance on the start date of the
   * filtering from the groupedBalanceWithoutRevals object
   * @private
   */
  private async setStartingGlossBalance() {
    this.perfService.mark("DashboardService::setStartingGlossBalance");
    this.startingBalance = null;
    let startingDate = subDays(new Date(this.filterStartDate), 1);

    if (this.dashboardConfig.scenario && new Date(this.anchorDate) < startingDate) {
      startingDate = new Date(this.anchorDate);
    }

    const firstStartingDate = new Date(this.firstStartingDate);
    if (startingDate.getTime() >= firstStartingDate.getTime()) {
      // We only want to run this once, and we only need to trigger it if the filter date is moved past the
      // original starting date
      if (!this.groupedBalanceWithoutRevals) {
        // get a groupedBalance object for balanced transactions before revals are added
        this.groupedBalanceWithoutRevals =
          await this.transactionCalculationService.recalculateBalance(
            this.balancedTransactions,
            true,
            null,
            [dayGranularity],
            true
          );
      }

      const lastTransactionDate = new Date(getLastTransactionDate(this.balancedTransactions));
      if (lastTransactionDate < startingDate) {
        startingDate = lastTransactionDate;
      }

      if (this.filterStartDate && this.groupedBalanceWithoutRevals) {
        const groupingKey = this.groupedBalanceWithoutRevals.getDateGroupingKey(
          startingDate,
          dayGranularity
        );

        //TODO: this only works with account filtering. Need to add in category-renderer and classification later.
        const balance = this.transactionCalculationService.getFilteredAccountBalances(
          this.groupedBalanceWithoutRevals,
          dayGranularity,
          groupingKey,
          this.filteredAccounts
        );

        if (balance) {
          this.startingBalance = balance;
        }
      }
    }
    this.perfService.markEnd();
  }

  private setTableTransactionDataFromGraphData() {
    this.tableTransactions = this.graphTransactions.filter(
      (transaction) => transaction.definition !== TransactionStatusEnum.fake
    );
    this.logger.info(`Number of transactions in dash of filter: ${this.tableTransactions.length}`);
    this.mockAccounts = [];
  }

  isScenarioEndBalanceDifferent() {
    if (this.scenarioKey === 0) {
      return true;
    }

    const previousScenarioKey = this.scenarioKey - 1;

    const balanceGroupingTool = new BalanceGroupingTools();
    const prevBalance = balanceGroupingTool.getCurrencyBalanceByDate(
      this.scenarioData.scenario[previousScenarioKey].groupedBalance,
      this.baseCurrency,
      new Date(this.filterEndDate)
    );

    const currentBalance = balanceGroupingTool.getCurrencyBalanceByDate(
      this.scenarioData.scenario[this.scenarioKey].groupedBalance,
      this.baseCurrency,
      new Date(this.filterEndDate)
    );

    return prevBalance !== currentBalance;
  }

  private async setTableTransactionsFromScenarioData() {
    this.tableTransactions =
      this.scenarioData.scenario[this.scenarioKey].createdRecords.transactions;
    /* TODO - @Michelle@Sinan -  They want to see 0 balance or negative balance transactions on the table. But if a scenario is not making any difference then we display a message to user as to why it is the case.
        so when the balance is different we display transactions and add those transactions to the table. It would be better to be able to handle those messages in the calculations and just send them to UI, but i did not
        want to break the code . So I handle it somehow in the no-rows-overlay-component-of-dash-table.component.ts . Sorry for the name :(
    */
    const isBalanceDiff = this.isScenarioEndBalanceDifferent();
    if (isBalanceDiff) {
      const negativeTransactions: Transaction[] = await this.getBalanceFakeTransactions();
      this.tableTransactions = this.tableTransactions.concat(negativeTransactions);
    }

    this.mockAccounts = this.scenarioData.scenario[this.scenarioKey].createdRecords.accounts;
  }

  private async getBalanceFakeTransactions(): Promise<Transaction[]> {
    const negativeBalanceTransactions: Transaction[] = [];
    for (const accountId in this.anchorPointBalance) {
      const accountGroupingNode = this.anchorPointBalance[accountId];
      const symbols = Object.keys(accountGroupingNode.balance.runningTotalValue);
      for (const symbol of symbols) {
        const accountFakeTransaction: Transaction = await this.getAccountFakeTransaction(
          accountGroupingNode,
          symbol
        );
        if (accountFakeTransaction) {
          negativeBalanceTransactions.push(accountFakeTransaction);
        }
      }
    }

    return negativeBalanceTransactions;
  }

  async isInterestRate() {
    const institutionService = this.injector.get(InstitutionService);
    return this.allAccounts.some(async (account) => {
      const institution = await institutionService.getInstitutionById(
        account.institutionLink.institutionId
      );
      const institutionAccount = await institutionService.filterInstitutionAccountById(
        account.institutionLink.institutionAccountId,
        institution
      );
      return institutionAccount && institutionAccount?.interestRates.length > 0;
    });
  }

  // TODO clean up this function @Sinan
  /** Generates the fake transactions for the scenario tables.*/
  async getAccountFakeTransaction(
    accountGroupingNode: GroupingNode,
    symbol: string
  ): Promise<Transaction> {
    const amount = accountGroupingNode.balance.runningTotalValue[symbol].symbolAmount.amount;
    const account = this.allAccounts.find((acc) => acc.id === accountGroupingNode.groupingKey);
    const institutionService = this.injector.get(InstitutionService);
    const institution = await institutionService.getInstitutionById(
      account.institutionLink.institutionId
    );
    const institutionAccount = await institutionService.filterInstitutionAccountById(
      account.institutionLink.institutionAccountId,
      institution
    );

    if (this.scenarioKey === 0 && amount > 0 && institutionAccount?.interestRates.length > 0) {
      return null;
    }

    if (this.scenarioKey === 1 && amount > 0 && (await this.isInterestRate())) {
      return null;
    }

    if (this.scenarioKey === 2 && amount > 0) {
      return null;
    }

    this.transactionNormalizeService = !this.transactionNormalizeService
      ? this.injector.get(TransactionNormalizeService)
      : this.transactionNormalizeService;
    this.classificationService = !this.classificationService
      ? this.injector.get(ClassificationService)
      : this.classificationService;
    const defaultSplitClassification =
      await this.classificationService.createDefaultSplitClassification();

    const description =
      amount < 0
        ? "Negative balance account"
        : amount === 0
        ? "Zero balance account"
        : "Non-interest bearing account";
    return await this.transactionCalculationService.createFakeTransaction(
      accountGroupingNode.groupingKey,
      amount,
      symbol,
      new Date(),
      description,
      this.transactionNormalizeService,
      defaultSplitClassification
    );
  }

  private async createTransactionsFromBalance(): Promise<Transaction[]> {
    this.perfService.mark("DashboardService::createTransactionsFromBalance");
    this.transactionNormalizeService = this.injector.get(TransactionNormalizeService);
    const startingTransactions = [];
    this.classificationService = this.injector.get(ClassificationService);

    const defaultClassification =
      await this.classificationService.getGeneralDefaultClassification();
    const fakeClassifications: SplitClassificationType[] = [];
    fakeClassifications.push({
      classificationId: defaultClassification.id,
      weight: 1,
      roundingDefault: true,
      name: defaultClassification.name,
    });

    let startingDate = subDays(new Date(this.filterStartDate), 1);
    if (this.dashboardConfig.scenario && new Date(this.anchorDate) < startingDate) {
      startingDate = new Date(this.anchorDate);
    }

    for (const accountId in this.startingBalance) {
      for (const symbol in this.startingBalance[accountId].balance.runningTotalValue) {
        const symbolAmount =
          this.startingBalance[accountId].balance.runningTotalValue[symbol].symbolAmount.amount;
        const fakeTransactionResponse = new TransactionResponse({
          __v: 1,
          accountId,
          description: "Generated starting transaction for revaluations",
          quantity: symbolAmount,
          symbol: symbol,
          date: startingDate.toDateString(),
          definition: TransactionStatusEnum.fake,
        });
        const fakeTransaction = new Transaction(fakeTransactionResponse);
        fakeTransaction.classifications = fakeClassifications;
        fakeTransaction.categories = [];

        await this.transactionNormalizeService.normalizeImportedTransaction(fakeTransaction);

        // set the currency of the quantity
        if (!fakeTransaction.quantity.currency && fakeTransactionResponse.valuation.value.symbol) {
          fakeTransaction.quantity.currency = fakeTransactionResponse.valuation.value.symbol;
        }
        // set the valuation price
        if (!fakeTransaction.valuationPrice && fakeTransactionResponse.valuation.symbolValue) {
          fakeTransaction.valuationPrice = fakeTransactionResponse.valuation.symbolValue;
        }
        startingTransactions.push(fakeTransaction);
      }
    }

    this.perfService.markEnd();
    return startingTransactions;
  }

  async checkGranularity(balanceGrouping: BalanceGrouping) {
    // check if the granularity has previously been calculated
    if (!balanceGrouping?.granularity?.[this.granularity]) {
      await balanceGrouping.addGranularityToBalance(this.granularity);
    } else if (this.granularity === dayGranularity && !balanceGrouping.fillDaily) {
      await balanceGrouping.fillTheGranularityGaps([this.granularity], true);
    }
  }

  setDefaultStartDate(transactions: Transaction[]) {
    return transactions.length > 0
      ? getFirstTransactionDate(transactions)
      : this.setFormattedStartDate();
  }

  /**
   * Function will call the scenarioGraph function to set the correct Scenario Group
   * and then work out the balances and the winners.
   * @param scenarioGraph
   * @param callback
   */
  async getScenarioBalances(
    scenarioGraph: GraphScenarioElements,
    callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>
  ): Promise<void> {
    this.perfService.mark("DashboardService::getScenarioBalances");

    try {
      this.getAnchorPointBalance();
      scenarioGraph.setStartingBalance(this.anchorPointBalance);
      await scenarioGraph.generateScenarioBalances(callback, this.submitProgressMessage.bind(this));

      this.perfService.markEnd();
      // return scenarioBalances;
    } catch (e) {
      this.logger.error(e);
      throw e;
    }
  }

  /**
   * Using the original balance line function. Set the parameters to get the graph data
   * for the balance line for the scenario without actually triggering the observables that
   * will replot the graph.
   *
   * @private
   */
  private async calculateBalanceLineForScenarios(refreshBalance = true) {
    // work out the balance points without triggering any observables
    await this.filterDashboardTransactions(
      this.filteredAccounts,
      this.filteredDirection,
      this.filteredCategories,
      this.filteredClassifications,
      null,
      refreshBalance
    );
  }

  private async calculateScenarioLine(
    scenarioGraph: GraphScenarioElements,
    scenarioBalances: GroupScenarioBalance[]
  ) {
    // use the Graph Scenario Element class to work out what we need to plot
    // given the groupedBalance object
    const filters = this.combineFilterParameters();

    return await scenarioGraph.calculateGraphData(
      scenarioBalances,
      this.granularity,
      this.filterStartDate,
      this.filterEndDate,
      filters
    );
  }

  async graphTransactionsOnly(refreshBalance = true) {
    await this.filterDashboardTransactions(
      this.filteredAccounts,
      this.filteredDirection,
      this.filteredCategories,
      this.filteredClassifications,
      null,
      refreshBalance
    );
  }

  getScenariosEndDateBalances(
    scenarioBalances: GroupScenarioBalance[],
    symbol: string[] = [this.baseCurrency]
  ): number[] {
    const balances = [];
    const balanceGroupingTool = new BalanceGroupingTools();
    for (const scenarioBalance of scenarioBalances) {
      const filterDateBalance = balanceGroupingTool.getCurrencyBalanceByDate(
        scenarioBalance.groupedBalance,
        symbol[0],
        new Date(this.filterEndDate)
      );
      balances.push(filterDateBalance);
    }
    return balances;
  }

  getUniqueScenarioBalances(scenarioBalances: GroupScenarioBalance[]): GroupScenarioBalance[] {
    const uniqueBalances: GroupScenarioBalance[] = [scenarioBalances[0]];
    let comparableScenarioIndex = 0;
    const scenarioEndDateBalances = this.getScenariosEndDateBalances(scenarioBalances, [
      this.baseCurrency,
    ]);

    if (scenarioEndDateBalances[comparableScenarioIndex] >= scenarioEndDateBalances[1]) {
      scenarioBalances[1].createdRecords = {
        accounts: [],
        transactions: [],
      };
      scenarioBalances[1].helpInfo = {};
    } else {
      comparableScenarioIndex = 1;
    }

    if (scenarioEndDateBalances[comparableScenarioIndex] >= scenarioEndDateBalances[2]) {
      scenarioBalances[2].createdRecords = {
        accounts: [],
        transactions: [],
      };
      scenarioBalances[2].helpInfo = {};
    }

    uniqueBalances.push(scenarioBalances[1]);
    uniqueBalances.push(scenarioBalances[2]);

    return uniqueBalances;
  }
  /**
   * graphScenario - this function is called when scenario graphing needs to be triggered.
   * Currently, being triggered off a dodgy button.
   */
  async graphScenario(keepSelectedDates = false, refreshBalance = true) {
    this.perfService.mark("DashboardService::graphScenario");
    this.scenarioGraph = new GraphScenarioElements(this.injector);

    // initialize the selected scenarioGroup details
    // TODO: update this eventually to set the scenarioGroup ID
    await this.scenarioGraph.updateScenarioGroup();
    this.scenarioGraph.setDatePreferences(this.datePreferences);

    // extend the end date of the graph
    this.anchorDate = this.scenarioGraph.getActiveAnchorPoint().date;
    this.defaultScenarioStartDate = this.anchorDate;
    const endDateAfterAnchorPoint = this.scenarioGraph.getDefaultEndDateAfterAnchorPoint().date;
    // let plotAnchorPoint = true;

    if (!keepSelectedDates) {
      if (!this.dashboardConfig.transaction) {
        this.filterStartDate = this.defaultScenarioStartDate;
      } else {
        this.filterStartDate = this.firstStartingDate;
        if (
          this.dashboardConfig.scenario &&
          new Date(this.defaultScenarioStartDate) < new Date(this.filterStartDate)
        ) {
          this.filterStartDate = this.defaultScenarioStartDate;
        }
      }
      this._defaultStartDate.next(this.filterStartDate);
      this.filterEndDate = endDateAfterAnchorPoint;
      this._defaultEndDate.next(this.filterEndDate);

      this.updateGranularityOptions();
    }

    /*
    if (new Date(this.filterStartDate) > new Date(this.anchorDate)) {
      // do not plot the anchor point because we are past it
      plotAnchorPoint = false;
    }
     */

    this.scenarioGraph.setEndDate(this.filterEndDate);

    if (this.dashboardConfig.transaction) {
      // work out the balance points without triggering any observables
      await this.calculateBalanceLineForScenarios(refreshBalance);
    } else {
      await this.calculateBalanceLineForScenarios(refreshBalance);
      this.setGraphData([], true);
    }
  }

  async graphScenarioAfterBalanceReturned() {
    await this.getScenarioBalances(
      this.scenarioGraph,
      this.graphScenariosAfterScenarioBalanceReturned.bind(this)
    );
  }

  async graphScenariosAfterScenarioBalanceReturned(
    scenarioBalances: GroupScenarioBalance[]
  ): Promise<void> {
    //TODO : @Sinan@Michelle => If the scenario's end date balance is less than or equal to the previous scenario's end date balance, no need to show it as user already has the best possible option
    // scenario-1 is always in place , we just play with scenario 2&3.
    let plotAnchorPoint = true;

    if (new Date(this.filterStartDate) > new Date(this.anchorDate)) {
      // do not plot the anchor point because we are past it
      plotAnchorPoint = false;
    }

    const scenarioHasMultipleDates =
      Object.keys(scenarioBalances[0].groupedBalance.granularity["day"]).length > 1;
    const uniqueScenarioBalances: GroupScenarioBalance[] = scenarioHasMultipleDates
      ? this.getUniqueScenarioBalances(scenarioBalances)
      : scenarioBalances;

    const scenarioGraphData = await this.calculateScenarioLine(
      this.scenarioGraph,
      uniqueScenarioBalances
    );

    const anchorPointTotalBalance = this.transactionCalculationService.getNormalizedBalanceByDate(
      this.groupedBalances,
      new Date(this.anchorDate)
    );

    let anchorPointData: AnchorPointData = null;
    if (plotAnchorPoint) {
      let groupedBalance;
      if (this.dashboardConfig.transaction) {
        groupedBalance = this.groupedBalances;
      }
      anchorPointData = {
        anchorDate: this.anchorDate,
        anchorBalance: anchorPointTotalBalance,
        groupedBalance: groupedBalance,
      };
    }

    let balance: GraphDataSet[] = [];

    if (this.dashboardConfig.transaction) {
      balance = this.graphData;
      if (balance.length > 0) {
        const lastBalanceDate = BlobbyUtils.setYMDToDate(
          Number(balance[balance.length - 1].endDate)
        );

        if (
          lastBalanceDate >= new Date(this.anchorDate) ||
          lastBalanceDate.toDateString() === new Date(this.anchorDate).toDateString()
        ) {
          balance.pop();
        }
      }
    }

    // TODO : @Sinan@Michelle => Since we remove transactions from the scenarios we dont show , we dont want to display them on the graph either.
    const uniqueScenarioGraphData = scenarioGraphData.filter((groupScenarioBalance) => {
      if (
        groupScenarioBalance.createdRecords.transactions.length === 0 &&
        groupScenarioBalance.scenarioType !== "currentInterestRate"
      ) {
        groupScenarioBalance.graphData = [];
      }

      return groupScenarioBalance;
    });
    const scenarioData = {
      scenario: uniqueScenarioGraphData,
      balance: balance,
      anchorPoint: anchorPointData,
    };

    this.setScenarioData(scenarioData);
    // If scenario only dashboard we need to trigger the transaction table not to load
    // the regular transactions by default
    if (this.dashboardConfig.type !== transactionOnly) {
      await this.updateTransactionsTable("scenario", this.initialScenarioKey);
    } else {
      await this.triggerTransactionObservables();
    }
    this.perfService.markEnd();
  }

  resetToTransactionDates() {
    this.filterStartDate = this.firstStartingDate; // set to the first transaction date
    this._defaultStartDate.next(this.filterStartDate);
    this.filterEndDate = this.setFormattedEndDate(); // set to the current date
    this._defaultEndDate.next(this.filterEndDate);
  }

  resetFilters() {
    this.sliderDates = null;
    if (!this.dashboardConfig.scenario) {
      this.tableType = "transaction";
      this.resetToTransactionDates();
    }
  }

  getAnchorPointBalance() {
    this.anchorPointBalance = this.transactionCalculationService.getBalanceGroupingByAccountsOnDate(
      this.groupedBalances,
      new Date(this.anchorDate)
    );
  }

  getBalanceOnDate(groupedBalance: BalanceGrouping, date: Date) {
    return this.transactionCalculationService.getNormalizedBalanceByDate(groupedBalance, date);
  }

  updateGranularityOptions() {
    this.defaultGranularity = this.getDefaultGranularityForDates(
      this.filterStartDate,
      this.filterEndDate
    );
    this.granularity = this.defaultGranularity;
    this._defaultGranularity.next(this.defaultGranularity);

    this.granularityOptions = this.getGranularityOptionsForDates(
      this.filterStartDate,
      this.filterEndDate
    );
    this._granularityOptions.next(this.granularityOptions);
  }

  getDefaultGranularityForDates(start: string, end: string): GranularityProperty {
    const startDate = new Date(start);
    const endDate = new Date(end);

    for (const granularityDefault of GranularityDefaults) {
      const endDateLimit = this.calculateEndDateLimit(
        startDate,
        granularityDefault.limit,
        granularityDefault.limitType
      );

      if (endDateLimit === null) {
        return granularityDefault.default;
      } else if (isBefore(endDate, endDateLimit)) {
        return granularityDefault.default;
      }
    }
  }

  getGranularityOptionsForDates(start: string, end: string): Array<GranularityProperty> {
    const startDate = new Date(start);
    const endDate = new Date(end);

    for (const granularityOption of GranularityOptions) {
      const endDateLimit = this.calculateEndDateLimit(
        startDate,
        granularityOption.limit,
        granularityOption.limitType
      );

      if (endDateLimit === null) {
        return granularityOption.options;
      } else if (isBefore(endDate, endDateLimit)) {
        return granularityOption.options;
      }
    }
  }

  calculateEndDateLimit(startDate: Date, limit: number, limitType: GranularityProperty) {
    // if there is no limit, then return null as the end date
    if (limit === null) {
      return null;
    }
    let endDateLimit = new Date(startDate);
    switch (limitType) {
      case dayGranularity:
        endDateLimit = addDays(new Date(startDate), limit);
        break;
      case weekGranularity:
        endDateLimit = addWeeks(new Date(startDate), limit);
        break;
      case monthGranularity:
        endDateLimit = addMonths(new Date(startDate), limit);
        break;
      case yearGranularity:
        endDateLimit = addYears(new Date(startDate), limit);
        break;
    }
    return endDateLimit;
  }

  async setGroupedBalance(groupedBalance: BalanceGrouping) {
    this.groupedBalances = groupedBalance;
    await this.completeGraphWithGroupedBalance();
  }

  async completeGraphWithGroupedBalance() {
    this.perfService.mark("DashboardService::next(this.groupedBalances)");
    this._groupBalances.next(this.groupedBalances);

    const filters = this.combineFilterParameters();

    let graphData: Array<GraphDataSet> = [];
    this.perfService.mark("DashboardService::formatDataSets");
    if (!this.dashboardConfig.scenario) {
      graphData = await this.primarySummaryGraphService.formatDataSets(
        this.groupedBalances,
        this.granularity,
        this.filterStartDate,
        this.filterEndDate,
        this.transactionCalculationService,
        filters
      );
    } else {
      if (new Date(this.filterStartDate) < new Date(this.anchorDate)) {
        graphData = await this.primarySummaryGraphService.formatDataSets(
          this.groupedBalances,
          this.granularity,
          this.filterStartDate,
          this.balanceEndDate,
          this.transactionCalculationService,
          filters
        );
      }
    }
    this.perfService.mark("DashboardService::setGraphData");
    if (!this.dashboardConfig.scenario) {
      this.setGraphData(graphData, true);
      await this.triggerTransactionObservables();
    } else {
      this.setGraphData(graphData, false);
      await this.graphScenarioAfterBalanceReturned();
    }
    this.perfService.markEnd();
    //TODO :@Sinan update this properly when are able to determine the end of the graph and table drawing. Cuz it is taking some time to complete.
    setTimeout(() => {
      this.setIsSpinner(false);
    }, 2000);
  }

  createActionProgress(action: string) {
    this.currentAction = action;
    this.progressPercentage = 0;
  }

  submitProgressMessage(action: string, progressPercentage: number) {
    if (action !== this.currentAction) {
      // trigger the current progress bar to move to 100%
      this.progressPercentage = 100;
      this.createActionProgress(action);
    } else {
      this.progressPercentage = progressPercentage;
    }

    // TODO: use the call to this function to trigger movement across a progress bar
    this.logger.info(`PROGRESS MESSAGE: ${action} is ${progressPercentage}% complete`);
  }
}
