import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

export type RadioOption = {
  label?: string;
  labelTemplate?: TemplateRef<any>;
  templateData?: any;
  value: string;
  selected: boolean;
  disabled?: boolean;
  labelType?: string;
};

@Component({
  selector: "radio-button",
  templateUrl: "./radio-button.component.html",
})
export class RadioButtonComponent {
  @Input() option: RadioOption;
  @Input() selectedValue: string;
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();
  selected: boolean;

  onButtonClick(value: string) {
    this.onClick.emit(value);
    this.option.selected = true;
  }
}
